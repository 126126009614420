import different from "../../../../content/posts/blogs/2024/dot-plot/different.jsx";
import inversion from "../../../../content/posts/blogs/2024/dot-plot/inversion.jsx";
import fullRepeat from "../../../../content/posts/blogs/2024/dot-plot/fullRepeat.jsx";
import repetitiveRegion from "../../../../content/posts/blogs/2024/dot-plot/repetitiveRegion.jsx";
import selfComparison from "../../../../content/posts/blogs/2024/dot-plot/selfComparison.jsx";
import insertionDeletion from "../../../../content/posts/blogs/2024/dot-plot/insertionDeletion.jsx";
import maz from "../../../../content/posts/blogs/2024/dot-plot/maz.jsx";
import * as React from 'react';
export default {
  different,
  inversion,
  fullRepeat,
  repetitiveRegion,
  selfComparison,
  insertionDeletion,
  maz,
  React
};